import { Link, useStaticQuery, graphql } from "gatsby";
import React from "react";
import Layout from "../components/layout";
import Button from "react-bootstrap/Button";
import { StaticImage } from "gatsby-plugin-image";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import Faq from "../components/faq";
import ServiceHero from "../components/service-hero";
import ImageSide from "../components/image-side";
import OpeningBanner from "../components/opening-banner";
import ThreeImageSection from "../components/three-image-section";
import Reviews from "../components/reviews";
import PriceListSection from "../components/price-list-section";
import { checkPropertiesForNull } from "../utils/objectHelper";
import { SafeHtmlParser } from "../components/safe-ntml-parser";

const SummerHousesPage = () => {
  const data = useStaticQuery(graphql`
    query {
      reviews {
        reviewCount
        reviewAuthor
        reviewDate
        reviewContent
      }
      schemaLogo: wpMediaItem(title: { eq: "tops-logo" }) {
        altText
        localFile {
          publicURL
          childImageSharp {
            original {
              height
              width
            }
            gatsbyImageData(
              formats: [AUTO, WEBP]
              quality: 100
              transformOptions: { cropFocus: CENTER, fit: CONTAIN }
              layout: CONSTRAINED
              placeholder: BLURRED
            )
          }
        }
      }

      site {
        siteMetadata {
          siteUrl
        }
      }
      pageData: wpPage(slug: { eq: "summerhouses" }) {
        seoFieldGroups {
          metaTitle
          metaDescription
          localBusinessSchema
          openGraphDescription
          openGraphTitle
          productSchema
          image {
            node {
              altText
              publicUrl
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    formats: [AUTO, WEBP]
                    quality: 100
                    transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                    layout: CONSTRAINED
                    placeholder: BLURRED
                  )
                  original {
                    width
                    height
                  }
                }
              }
            }
          }
        }
        summerhousesPageFieldGroups {
          summerhousesBannerSection {
            heading
            description
            cta1 {
              title
              target
              url
            }
            backgroundImage {
              node {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      formats: [AUTO, WEBP]
                      quality: 100
                      transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                      layout: CONSTRAINED
                      placeholder: BLURRED
                    )
                    original {
                      width
                      height
                    }
                  }
                }
              }
            }
            image {
              node {
                altText
                mediaItemUrl
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      formats: [AUTO, WEBP]
                      quality: 100
                      transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                      layout: CONSTRAINED
                      placeholder: BLURRED
                    )
                    original {
                      width
                      height
                    }
                  }
                }
              }
            }
          }
          summerhousesCtaSection1 {
            heading
            description
            cta {
              target
              title
              url
            }
            image {
              node {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      formats: [AUTO, WEBP]
                      quality: 100
                      transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                      layout: CONSTRAINED
                      placeholder: BLURRED
                    )
                    original {
                      width
                      height
                    }
                  }
                }
              }
            }
          }
          summerhousesFaqSection {
            heading
            image {
              node {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      formats: [AUTO, WEBP]
                      quality: 100
                      transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                      layout: CONSTRAINED
                      placeholder: BLURRED
                    )
                    original {
                      width
                      height
                    }
                  }
                }
              }
            }
          }
          summerhousesOpeningHoursSection {
            heading
          }
          summerhousesPerfectionSection {
            heading
            description
            cta {
              title
              target
              url
            }
            images {
              image {
                node {
                  altText
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        formats: [AUTO, WEBP]
                        quality: 100
                        transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                        layout: CONSTRAINED
                        placeholder: BLURRED
                      )
                      original {
                        width
                        height
                      }
                    }
                  }
                }
              }
            }
          }
          summerhousesReviewsSection {
            heading
          }
          summerhousesTwoColumnSection1 {
            heading
            description
            image {
              node {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      formats: [AUTO, WEBP]
                      quality: 100
                      transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                      layout: CONSTRAINED
                      placeholder: BLURRED
                    )
                    original {
                      width
                      height
                    }
                  }
                }
              }
            }
          }
          summerhousesTwoColumnSection2 {
            leftColumn {
              heading
              description
              image {
                node {
                  altText
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        formats: [AUTO, WEBP]
                        quality: 100
                        transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                        layout: CONSTRAINED
                        placeholder: BLURRED
                      )
                      original {
                        width
                        height
                      }
                    }
                  }
                }
              }
            }
            rightColumn {
              heading
              brochureImage {
                node {
                  altText
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        formats: [AUTO, WEBP]
                        quality: 100
                        transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                        layout: CONSTRAINED
                        placeholder: BLURRED
                      )
                      original {
                        width
                        height
                      }
                    }
                  }
                }
              }
              brochureLink {
                target
                title
                url
              }
              priceListLink {
                target
                title
                url
              }
              priceListImage {
                node {
                  altText
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        formats: [AUTO, WEBP]
                        quality: 100
                        transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                        layout: CONSTRAINED
                        placeholder: BLURRED
                      )
                      original {
                        width
                        height
                      }
                    }
                  }
                }
              }
            }
          }
          summerhousesTwoColumnSection3 {
            heading
            description
            cta {
              target
              title
              url
            }
            image {
              node {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      formats: [AUTO, WEBP]
                      quality: 100
                      transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                      layout: CONSTRAINED
                      placeholder: BLURRED
                    )
                    original {
                      width
                      height
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const {
    pageData: { seoFieldGroups, summerhousesPageFieldGroups },
  } = data;

  const {
    summerhousesBannerSection,
    summerhousesTwoColumnSection1,
    summerhousesTwoColumnSection2,
    summerhousesPerfectionSection,
    summerhousesOpeningHoursSection,
    summerhousesReviewsSection,
    summerhousesCtaSection1,
    summerhousesTwoColumnSection3,
    summerhousesFaqSection,
  } = summerhousesPageFieldGroups;

  const siteUrl = data.site.siteMetadata.siteUrl;

  const breadcrumb = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: "1",
        name: "Home",
        item: {
          url: `${siteUrl}`,
          id: `${siteUrl}`,
        },
      },
      {
        "@type": "ListItem",
        position: "2",
        name: "Summerhouses",
        item: {
          url: `${siteUrl}/summerhouses`,
          id: `${siteUrl}/summerhouses`,
        },
      },
    ],
  };

  const serviceSchema = {
    "@context": "https://schema.org/",
    "@type": "Product",
    "@id": "https://www.topsgardenbuildings.com/summerhouses",
    image: `https://www.topsgardenbuildings.com${seoFieldGroups?.image?.node.localFile.publicURL}`,
    mpn: "https://www.topsgardenbuildings.com/summerhouses",
    sku: "https://www.topsgardenbuildings.com/summerhouses",
    description: `${seoFieldGroups?.productSchema}`,
    logo: `https://www.topsgardenbuildings.com${data.schemaLogo.localFile.publicURL}`,
    name: "Tops Garden Buildings",
    url: "https://www.topsgardenbuildings.com",
    brand: {
      "@type": "Organization",
      logo: `https://www.topsgardenbuildings.com${data.schemaLogo.localFile.publicURL}`,
      image: `https://www.topsgardenbuildings.com${seoFieldGroups?.image?.node.localFile.publicURL}`,
      name: "Tops Garden Buildings",
      url: "https://www.topsgardenbuildings.com",
    },
    aggregateRating: {
      "@type": "AggregateRating",
      reviewCount: "125",
      ratingValue: "4.9",
      bestRating: "5",
      worstRating: "1",
    },

    review: {
      "@type": "Review",
      url: "https://www.topsgardenbuildings.com",
      datePublished: `${data.reviews.reviewDate}`,
      reviewBody: `${data.reviews.reviewContent}`,
      author: {
        "@type": "Person",
        name: `${data.reviews.reviewAuthor}`,
      },
      reviewRating: {
        "@type": "Rating",
        ratingValue: "4.9",
        bestRating: "5",
        worstRating: "1",
      },
      publisher: {
        "@type": "Organization",
        name: "Tops Garden Buildings",
        sameAs: "https://www.topsgardenbuildings.com",
      },
    },
  };

  return (
    <>
      <Helmet>
        {" "}
        <script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
      </Helmet>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(serviceSchema)}
        </script>
      </Helmet>

      <GatsbySeo
        title={seoFieldGroups?.metaTitle}
        description={seoFieldGroups?.metaDescription}
        language="en"
        openGraph={{
          type: "website",
          url: `${siteUrl}/summerhouses`,
          title: `${seoFieldGroups?.openGraphTitle}`,
          description: `${seoFieldGroups?.openGraphDescription}`,
          images: [
            {
              url: `${seoFieldGroups?.image?.node.localFile.publicURL}`,
              width: `${seoFieldGroups?.image?.node.localFile.childImageSharp.original.width}`,
              height: `${seoFieldGroups?.image?.node.localFile.childImageSharp.original.height}`,
              alt: `${seoFieldGroups?.image?.node.altText}`,
            },
          ],
        }}
      />

      <div>
        <Layout>
          {summerhousesBannerSection &&
            !checkPropertiesForNull(summerhousesBannerSection, ["heading"]) && (
              <ServiceHero
                backgroundImage={
                  summerhousesBannerSection.backgroundImage.node?.localFile
                    .childImageSharp.gatsbyImageData
                }
                title={summerhousesBannerSection?.heading}
                description={
                  <SafeHtmlParser
                    htmlContent={summerhousesBannerSection?.description}
                  />
                }
                ctaButton={summerhousesBannerSection?.cta1}
                image={summerhousesBannerSection?.image.node}
              />
            )}
          {summerhousesTwoColumnSection1 &&
            !checkPropertiesForNull(summerhousesTwoColumnSection1, [
              "heading",
            ]) && (
              <ImageSide
                order="last"
                mobileImageHeight="20rem"
                svgPos="1rem"
                title={summerhousesTwoColumnSection1?.heading}
                text={
                  <SafeHtmlParser
                    htmlContent={summerhousesTwoColumnSection1?.description}
                  />
                }
                imageHeight="25rem"
                image={
                  summerhousesTwoColumnSection1.image?.node?.localFile
                    .childImageSharp.gatsbyImageData
                }
                imageAlt={summerhousesTwoColumnSection1.image?.node?.altText}
              />
            )}
          {summerhousesTwoColumnSection2 &&
            !checkPropertiesForNull(summerhousesTwoColumnSection2, [
              "heading",
            ]) && (
              <PriceListSection
                bgColour="bg-light-grey"
                mobileImageHeight="20rem"
                title={summerhousesTwoColumnSection2.leftColumn?.heading}
                text={
                  <SafeHtmlParser
                    htmlContent={
                      summerhousesTwoColumnSection2.leftColumn?.description
                    }
                  />
                }
                brochure={
                  summerhousesTwoColumnSection2?.rightColumn?.brochureLink
                }
                brochureImage={
                  summerhousesTwoColumnSection2?.rightColumn?.brochureImage?.node
                }
                imageHeight="25rem"
                priceList={
                  summerhousesTwoColumnSection2?.rightColumn?.priceListLink
                }
                priceListImage={
                  summerhousesTwoColumnSection2?.rightColumn?.priceListImage
                    ?.node
                }
                image={
                  summerhousesTwoColumnSection2.leftColumn?.image.node
                    ?.localFile.childImageSharp.gatsbyImageData
                }
                imageAlt={
                  summerhousesTwoColumnSection2?.leftColumn?.image.node?.altText
                }
              />
            )}

          {summerhousesTwoColumnSection3 &&
            !checkPropertiesForNull(summerhousesTwoColumnSection3, [
              "heading",
            ]) && (
              <ImageSide
                order="last"
                mobileImageHeight="20rem"
                svg={
                  <StaticImage
                    className="position-absolute d-none d-lg-block"
                    style={{ zIndex: 1, top: "-4rem", right: "-4rem" }}
                    quality="100"
                    src="../images/single-leaf.svg"
                    placeholder="blurred"
                    width={130}
                    formats={["auto", "webp"]}
                  />
                }
                svgPos="1rem"
                title={
                  <SafeHtmlParser
                    htmlContent={summerhousesTwoColumnSection3?.heading}
                  />
                }
                text={
                  <>
                    <SafeHtmlParser
                      htmlContent={summerhousesTwoColumnSection3?.description}
                    />
                    {summerhousesTwoColumnSection3.cta &&
                      summerhousesTwoColumnSection3.cta.url && (
                        <Button
                          className="mt-4 w-100 w-md-auto"
                          href={summerhousesTwoColumnSection3.cta?.url}
                          variant="primary"
                          target={
                            summerhousesTwoColumnSection3.cta.target ?? "_blank"
                          }
                        >
                          {summerhousesTwoColumnSection3.cta?.title}
                        </Button>
                      )}
                  </>
                }
                imageHeight="25rem"
                image={
                  summerhousesTwoColumnSection3.image?.node?.localFile
                    .childImageSharp.gatsbyImageData
                }
                imageAlt={summerhousesTwoColumnSection3.image?.node?.altText}
              />
            )}

          {summerhousesPerfectionSection &&
            !checkPropertiesForNull(summerhousesPerfectionSection, [
              "heading",
            ]) && (
              <ThreeImageSection
                title={summerhousesPerfectionSection?.heading}
                text={
                  <div className="text-white">
                    <SafeHtmlParser
                      htmlContent={summerhousesPerfectionSection?.description}
                    />
                  </div>
                }
                imageHeight="15rem"
                images={summerhousesPerfectionSection.images}
                ctaButton={summerhousesPerfectionSection.cta}
              />
            )}

          <Faq
            imageHeight="25rem"
            page="Summerhouses"
            image={
              summerhousesFaqSection?.image?.node?.localFile.childImageSharp
                .gatsbyImageData
            }
            imageAlt={summerhousesFaqSection?.image?.node?.altText}
            heading={summerhousesFaqSection?.heading}
          />
          <Reviews
            topPadding="pt-5 pt-md-0"
            title={summerhousesReviewsSection?.heading}
          />

          <OpeningBanner heading={summerhousesOpeningHoursSection?.heading} />
          {summerhousesCtaSection1 &&
            !checkPropertiesForNull(summerhousesCtaSection1, [
              "heading",
            ]) && (
              <ImageSide
                svg={
                  <StaticImage
                    className="position-absolute d-none d-lg-block"
                    style={{ zIndex: 1, top: "-4rem", left: "-4rem" }}
                    quality="100"
                    src="../images/leaf.svg"
                    placeholder="blurred"
                    width={130}
                    formats={["auto", "webp"]}
                  />
                }
                order="last"
                mobileImageHeight="20rem"
                title={summerhousesCtaSection1?.heading}
                text={
                  <span>
                    <SafeHtmlParser
                      htmlContent={summerhousesCtaSection1?.description}
                    />

                    {summerhousesCtaSection1?.cta &&
                      summerhousesCtaSection1.cta.url && (
                        <Button
                          className="mt-4 w-100 w-md-auto"
                          as={Link}
                          to={summerhousesCtaSection1?.cta?.url}
                          variant="primary"
                        >
                          {summerhousesCtaSection1.cta?.title}
                        </Button>
                      )}
                  </span>
                }
                imageHeight="25rem"
                image={summerhousesCtaSection1.image.node?.localFile.childImageSharp.gatsbyImageData}
                imageAlt={summerhousesCtaSection1.image.node?.altText}
              />
            )}
        </Layout>
      </div>
    </>
  );
};

export default SummerHousesPage;
